import React from 'react';
import FancyBox from '../../components/FancyBox';
import PageHeader from '../../components/PageHeader';

function PrivacyPage() {
	return (
		<div className="pageAbout">
			<PageHeader>Privacy policy</PageHeader>
			<FancyBox>
				The protection of your personal data is important to us. We are constantly working to improve our processing of your personal data.
				This privacy policy contains information about how Pervanovo[1] handles your personal data in accordance with the European Data Protection Regulation ("GDPR"). We encourage you to review our policy. In some cases, we will ask for your consent for processing personal data.  
				In order to further ensure our continuous work on data protection issues, we have also chosen to have a data protection officer. You will find all our contact details under section 13.
				[1] By "Pervanovo" we mean all companies in the Pervanovo group: Pervanovo Invest AB, Pervanovo d.o.o, Pervan Holding AB
			</FancyBox>
			<FancyBox>
			<h1>1. Main concepts</h1>
			<p>Personal data is information that, individually or in combination with other information, can be used to identify, locate or contact a person. Examples of personal data are name, email address, phone number or IP address.</p>

			<p>Processing of personal data means any kind of handling of personal data, for example, when we collect information about a customer in order to deliver a product. Under section 5 you can read about the purposes for which we may process your personal information.</p>

			<p>The data controller is the company which determines what personal data shall be requested and what the purpose to this is. It is the data controller’s responsibility to ensure that the processing of your personal data is in accordance with applicable personal data regulation.</p>

			<h1>2. Pervanovo Invest AB is responsible</h1>
			<p>Pervanovo Invest AB is responsible for compliance with the obligations concerning the processing of your personal data in the Pervanovo group (joint controllers).​</p>

			<h1>3. What types of data do we collect?</h1>
			<p>When you get in touch with us, e.g. via email, phone or visit our website, we process personal data. Depending on the situation we may ask for the following personal data:</p>
			<ul>
			<li>Name</li>
			<li>Email address</li>
			<li>Country and/ or region</li>
			<li>Address</li>
			<li>Phone number</li>
			<li>Which company you work for</li>
			<li>Job title</li>
			<li>Which industry you work in</li>
			<li>Website address of your company</li>
			<li>Other data that is featured in the communication</li>
			<li>Usernames on Facebook, LinkedIn, Instagram and Twitter when communicating with Pervanovo through social media, as well as other information that you may provide.</li>
			<li>Technical Data: The URL through which you can access our web pages, your IP address and user-type, browser type, language, and identification and operating system information.</li>
			</ul>
			<h1>4. Information from other sources</h1>
			<p>We may also process other data about you that you have previously provided to us. Based on publicly available information, we may also complete your registered data with us. If you are a customer to us, we may complete your data with additional contact information.​</p>

			<h1>5. How do we use the data?</h1>
			<p>We always strive for only processing the personal information that is necessary to fulfill our commitments to you. In addition, personal data may also be used for certain limited purposes. (For recruitment purposes, see section 9.)​</p>

			<p>The information you provide from you is used for the following purposes:</p>
			<ul>
			<li>Communicate with you and respond to requests</li>
			<li>Fulfill our commitments according to an agreement with you or in accordance with law</li>
			<li>Sales and marketing activities in the form of direct mail, which may also include product offers and other content that you may be interested in to give you a more personal experience (see section 6) or social media updates</li>
			<li>Customer care and information about our products</li>
			<li>To get statistics and information about user behavior to improve our website, social media channels and the user experience of these</li>
			<li>Maintain a customer register</li>
			</ul>
			<h1>6. Newsletters and other direct marketing</h1>
			<p>As an example, when you are or become a customer to Pervanovo or have shown interest in our products and activities (e.g. by subscribing to our newsletter), we may send you our newsletter or other direct marketing to you via email. These will contain information about our products or other events and updates within Pervanovo. Within the framework of GDPR, we are of the opinion that we have a legitimate interest in maintaining business relations and communicating with you about our products and operations.​</p>

			<p>You can update your subscription settings and decline future marketing emails by following the link you find at the bottom of all our marketing emails, or by contacting us directly.</p>

			<h1>7. For how long is the data stored?</h1>
			<p>We process personal data for no longer then what is necessary to fulfill the purpose of its collection, then we delete the data.​</p>

			<p>If you have an active dialogue with us, your data is stored for 36 months from the latest interaction; then we will discard the data we have registered about you. An active dialogue is defined as having interacted with Pervanovo or representatives of Pervanovo by telephone, email, downloading materials on the website or registering via a form.​</p>

			<p>If you are a customer or licensee to us, we will save the data for seven years after the termination of the agreement in accordance with law, e.g. the Swedish Accounting Act.​</p>

			<p>We process your personal data for email newsletters or other direct mail until you terminate your subscription. Then we will delete the personal data.​</p>

			<p>When you are employed by a company that is a customer, partner or licensee to us, we process your data in order to fulfill our agreement and to give customer care. During active relationships with your company, we process your data until either i.you have terminated your employment at the company or ii.the company no longer has an active relationship with us. If a relationship with a customer, partner or licensee has ended, our storage and processing of your data will follow the same conditions as described in the previous two paragraphs. If you terminate your employment at the company, you are responsible for notifying us so that we can delete your information.​</p>

			<p>Please note that some information may be processed for longer than what is stated above if we are required to do so by law, by a governmental or court decision, due to a legal dispute or when there is a risk of the latter or similar. ​</p>

			<h1>8. Who has access to your personal data?</h1>
			<p>We constantly strive for that your personal data is only processed by those who need to. Generally, data will be available to persons within the company who work in the departments of marketing, law, finance and communication. In addition, data can be processed by other people at Pervanovo that you are in direct contact with.​</p>
			</FancyBox>
			
			<FancyBox>
				<h1>9. Job application and recruitment</h1>
				<p>If you are applying to work with us, we may process further data about you:</p>
				<ul>
					<li>contact information (such as name, mailing address, email address and phone number),</li>
					<li>employment history and education,</li>
					<li>language skills and other work-related skills,</li>
					<li>social security number, national ID number or other government-issued ID number,</li>
					<li>birth date,</li>
					<li>gender,</li>
					<li>citizenship and status regarding work permits,</li>
					<li>disability and health-related information,</li>
					<li>where relevant, results from drug tests, extract from the criminal records registry and other background checks,</li>
					<li>where relevant, results from personality tests,</li>
					<li>information about references and the information provided through these references, as well as</li>
					<li>other information that you may provide us, such as in your resume or application or other information regarding your qualifications for employment.</li>
				</ul>
				<p>When the recruitment process is over, your personal information will be deleted unless you have given us your consent to keep it. However, some information may be processed further if we are required to do so by law, by a governmental or court decision, due to a legal dispute or when there is a risk of the latter or similar. </p>

				<p>More information about how we process personal data in connection with recruitment may be given in connection with a specific recruitment process.</p>

				<h1>10. Shared information with our partners</h1>
				<p>We neither sell, rent, distribute or otherwise make your personal data available to third parties. However, we may share information with companies within the same company group, partners or suppliers for the purposes stated in this policy.</p>


				<h1>11. Security, storage and transfer</h1>
				<p>We use administrative, technical and physical security measures to protect the personal data you provide against accidental, illegal or unauthorized destruction, loss, modification, access, disclosure or use.</p>

				<p>We may need to share your personal data with our international offices in the US and China so messages from these offices also can be sent to you. We may also need to allow our suppliers to access your personal data when they provide services to us, primarily to maintain and support our IT systems.</p>

				<p>Any transfer of data outside the EU/ EEA is made in accordance with applicable data protection laws. If there is no other legitimate reason, our international transfers of personal data (including transfers to our international offices outside the EU/ EEA) are based on the EU Commission's standard contractual clauses.</p>

				<p>This transfer of data, made to servers in so-called third countries, lives up to the rules of secure data transfer under the GDPR in accordance with the Privacy Shield Frameworks, which has been developed between the EU and the United States. More information about the Privacy Shield Frameworks can be found on this page: https://www.privacyshield.gov. </p>

				<p>Personal data can also be stored via IT services through Google, Microsoft, Slack or other IT services for different cloud-based solutions. Please note that these providers may locate their servers outside the EU/ EEA area and that Pervanovo can not take responsibility for these IT providers. However, we always strive to have secure IT solutions, both for our and your safety.</p>

				<h1>12. You are entitled to know what data we have about you and request rectification or erasure</h1>
				<p>You are entitled to get information about what data we have about you in the form of a registry extract, which you can also get digitally. You may also request that we rectify incorrect data, erase information about you or, under certain circumstances, transfer your information to another data controller according to your demands ("data portability"). You may also object to the processing of certain personal data or request that the processing of your personal data is restricted. If you have given us your consent to the processing of personal data, you are also entitled to withdraw this. If you withdraw your consent, we will erase the information we have about you due to the consent. Please note that restriction or erasure of your personal data may affect the delivery of information within the customer relationship, as well as possibly affecting any ongoing contact with you.</p>

				<p>If you wish to withdraw your consent, or request registry extracts, rectifications or erasure, please contact us via the email address we have provided in the contact details section below.</p>

				<h1>13. Contact information to Pervanovo Invest AB</h1>
				<p>If you have questions about our processing or wish to get in touch with us to exercise your rights, please find our contact information below.</p>
				<ul>
					<li>Pervanovo Invest AB</li>
					<li>Apelvägen 2</li>
					<li>263 62 Viken</li>
					<li>Sweden</li>
					<li>Company registration number: 556526-6532</li>
					<li>Email address: info@pervanovo.se</li>
				</ul>
				<h1>14. Updates to this data protection policy</h1>
				<p>This privacy policy (including any appendices) may be updated on a regular basis to reflect changes in our privacy practices. In the case of significant changes, we will disclose this by posting a clear notice on our website and we will indicate on the top of the policy when it was last updated.</p>

				<h1>15. Contact details of the Swedish Data Protection Authority</h1>
				<p>The responsible supervisory authority is the Swedish Data Protection Authority. You can contact the Swedish Data Protection Authority if you have questions about GDPR and its application or comments on Pervanovos's process of personal data.</p>

				<p>Website: <a href="www.datainspektionen.se">www.datainspektionen.se</a></p>

				<p>Email address: <a href="mailto:datainspektionen@datainspektionen.se">datainspektionen@datainspektionen.se</a></p>
			</FancyBox>
		</div>
	);
}

export default PrivacyPage;