import React from 'react';
import './NewsBox.scss';
import FancyBox from "../FancyBox";

function NewsBox(props) {
	let date = props.date ? props.date : '';
	return (
		<FancyBox className="newsBox">
			<div className="publishDate">{date}</div>
			<div>{props.children}</div>
		</FancyBox>
	);
}

export default NewsBox;