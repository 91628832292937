import React from 'react';

import NewsBox from "../../components/NewsBox";

import PageHeader from "../../components/PageHeader";
import Banner from "../../components/Banner";

import ogulinImage from "../../images/ogulin2-construction.jpg";
import ogulinMap from "../../images/ogulin2_map.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/pro-solid-svg-icons";
import {faGoogleDrive} from "@fortawesome/free-brands-svg-icons";

import sideboard from "./assets/sideboard_s1.jpg";

function News() {
	return (
		<div className="pageNews">
			<PageHeader>News</PageHeader>
			<NewsBox date={"2024-07-08"}>
				<h1>Annual update 2024</h1>
				<p>The annual business update for the Pervanovo Group for 2023-2024 is now available.</p>
				<p><a
					href="https://storage.pervanovo.se/downloads/Pervanovo%20Annual%20update%202024.pdf"
					download><FontAwesomeIcon icon={faDownload}/> Download annual report</a></p>
				<p><a href="https://docs.google.com/document/d/18FyvaqiM_MvJ3SoYJqTgf_F7DVtuZSmHk9eIuYvBudY/edit?usp=sharing"><FontAwesomeIcon icon={faGoogleDrive}/> Read online</a></p>
			</NewsBox>
			<NewsBox date="2023-07-14">
				<h1>Consolidated annual report January – December 2022</h1>
				<p>The consolidated annual report for the Pervanovo Group for the period January – December 2022 is now available.</p>
				<p><a href="https://storage.pervanovo.se/downloads/Pervanovo%20Consolidated%20annual%20report%20January%20%E2%80%93%20December%202022.pdf" download><FontAwesomeIcon icon={faDownload}/> Download annual report</a></p>
			</NewsBox>
			<NewsBox date="2022-11-14">
				<h1>Pervanovo acquires the Croatian furniture producer Spin Valis</h1>
				<Banner src={sideboard}/>
				<p>Pervanovo, the parent company of Bjelin industries in Croatia and Välinge Group in Sweden, has through its subsidiary Välinge Croatia signed an agreement to acquire 85% of the shares in the Croatian furniture company Spin Valis. The intention is to acquire 100% of the shares. The acquisition means that large-scale production and marketing of furniture with Välinge's click technology can be achieved faster than originally planned and the availability of oak material will increase. The acquisition requires approval from the antitrust authorities. Spin Valis will be renamed Bjelin Spinvalis.</p>
				<p>Spin Valis is one of Croatia's largest manufacturers of solid oak furniture. Production also includes elements, glued solid boards and electricity based on bioenergy and solar panels. Spin Valis in Pozega, Croatia, has 420 employees.</p>
				<blockquote>
					<p>We are pleased that we have now completed our fifth acquisition in Croatia. Major investments in new technology, buildings and machinery have been made at all production facilities. With our new facility in Ogulin, where we are building the largest wood flooring factory in Europe, our Croatian group of companies will be very efficient and competitive, especially in oak-based products.</p>
					<p>Spin Valis and all our other Croatian companies are very profitable. The acquisition of Spin Valis will increase our sales in Croatia by €30 million to €170 million and our operating result for 2022 will exceed €40 million. The number of employees in Croatia will increase to 2,200.</p>
					<p>Our intention is to continue developing Spin Valis as a premium furniture producer and together with our furniture production at Bjelin Spacva, our aim is to become one of the leading furniture manufacturers in Europe in our field. We will introduce Välinge’s high-tech click furniture technology on a large scale and coordinate wood processing with our other Croatian companies in Ogulin, Bjelovar, Vinkovci and Otok, The green electricity production at Spin Valis will contribute to making our products even more sustainable.</p>
				</blockquote>
				<figcaption>Darko Pervan, owner and CEO of Pervanovo Invest AB.</figcaption>
			</NewsBox>
			<NewsBox date="2022-07-18">
				<h1>Key figures 2021</h1>
				<p>The key figures below are based on management accounts and not on the official published figures.</p>
				<p>Increased sales and profits have increased the equity value of the whole Pervanovo group to about €2,600 million (June 2022) in spite of the general decrease of the stock markets in recent months. The net equity value after reduction of the Kirkbi minority holding is about €1,600 million.</p>
				<p>Sales in 2021 increased with about 42% to about €270 million and number of employees increased with 1,000 to 2,150 – about 300 in Sweden, 1,800 in Croatia and 50 in other countries. 24% of the growth was organic growth and 18% was related to the acquisition of Spačva.</p>
				<p>Net EBITDA after reduction of considerable cost related to R&D, start-up costs for new production lines and restructuring of acquired companies increased from €69 million to €100 million.</p>
				<p>The market value of Pervanovo's properties is about €300 million, considerably higher than the book value which is based on the original investments reduced with annual depreciation.</p>
				<p><a href={"https://storage.pervanovo.se/downloads/Pervanovo%20Consolidated%20annual%20report%20January%20%E2%80%93%20December%202021.pdf"} download><FontAwesomeIcon icon={faDownload} /> Download annual report</a></p>
			</NewsBox>
			<NewsBox date="2021-06-02">
				<h1>Pervanovo increases supply of oak materials through acquisition of veneer company Spačva d.d. in Croatia</h1>
				<p>Pervanovo Invest AB, headquartered in Viken Sweden and with subsidiaries in Croatia, has acquired 98% of the shares in the Croatian listed company Spačva d.d. in Vinkovci.</p>
				<p>Spačva has 850 employees and is one of Europe's largest manufacturers of oak veneers.</p>
				<p>Following the acquisition, Pervanovo will become Croatia's largest wood processing company with 1,700 employees and 5 production units.</p>
				<p>Pervanovo's two Croatian veneer companies, Bjelin Otok and Spačva d. d., located on the edge of Europe's largest continuous oak forest, the Spačva Forest, have a production capacity of 25 million m² of oak veneer. Pervanovo thus becomes Europe's largest manufacturer of high-quality FSC-certified oak veneers for the flooring and furniture industry.</p>
				<p>In a few years the main part of the oak veneer will be used internally to produce hardened wooden floors in Viken, Sweden and in Ogulin, Croatia according to the patented Woodura technology developed by Pervanovo's subsidiary Välinge in Viken.</p>
				<blockquote><p>There is a general shortage of oak, and the acquisition will double our oak supply. With our new Woodura technology, we can manufacture 10 times more hardened wooden floors from an oak log than when conventional technology is used. Through the acquisition, we have now secured all the oak veneers that we need to become Europe's leading wood flooring company with a production exceeding 20 million m².</p></blockquote>
				<figcaption>Darko Pervan, CEO of Pervanovo Invest AB</figcaption>
				<blockquote><p>We already buy large quantities of veneer from our sister company Bjelin Otok in Croatia. The veneer from the oak in the Spačva forest is specially adapted for Välinge's production of long and wide Woodura floors and the high quality and FSC certification is very appreciated by our customers in Europe, USA and Asia. The expanded production capacity enables us to both increase our own flooring production as well as to supply veneers to our Woodura licensees.</p></blockquote>
				<figcaption>Niclas Håkansson CEO Välinge</figcaption>
				<blockquote><p>When all shares have been acquired Spačva d.d. in Vinkovci will be delisted and renamed to Bjelin Spačva d.o.o. Through a coordination of our production units in Ogulin, Bjelovar, Otok and Vinkovci, the oak raw material can be utilized more efficiently. The veneer production in Vinkovci will be coordinated with our veneer production in Otok. Our veneer plants in Vinkovci and Otok process 90% of all veneer logs harvested in Spačva Forest. The short transport distances provide significant benefits in terms of costs and product quality. Major investments will be made in the two veneer plants to adapt the veneer to the production of Woodura floors and to expand capacity. The production of elements and lamellas for parquet floors in Vinkovci will be expanded and coordinated with our production in Bjelovar. In Vinkovci we will also produce furniture with Välinge's click technology. With all these investments and the availability of large quantities of FSC-certified oak material, our Croatian group of companies will become one of the leading and most cost-effective sustainable wood processing unit for oak raw materials in Europe. When all our planned investments are completed, the number of employees in Croatia will increase from 1,700 to about 2,500 and our sales will rise to about € 500 million. 80% of our future sales will be Woodura floors from our new factory Ogulin 2.</p></blockquote>
				<figcaption>Stjepan Vojnic CEO Pervanovo Croatia</figcaption>
			</NewsBox>
			<NewsBox date="2021-01-13">
				<h1>Construction starts to build world's largest wood flooring factory in Croatia</h1>
				<p>Woodura floors have been very well received by the market. Our new factory in Viken will reach its full capacity in 2023.</p>
				<p>To meet the growing market demand, Bjelin d.o.o. in Croatia, started in October 2020 a major investment in a new highly integrated and automated factory to produce wood floorings based on the Välinge Woodura technology.</p>
				<p>The new factory "Ogulin&nbsp;2" located close to Bjelin's present "Ogulin&nbsp;1" parquet factory in Ogulin, Croatia, will significantly increase the Woodura capacity in several steps from 2023. When completed in 2027, Ogulin&nbsp;2 will become the largest wood flooring factory in the world with a production capacity exceeding 20 million m². Total flooring capacity, including Välinge's production in Viken and Bjelin's production in Ogulin 1 will be around 30 million m².</p>
				<Banner src={ogulinImage}/>
				<p>The factory is located on a 330,000 m² land area along the highway between Zagreb and Split and comprises 100,000 m²of buildings. CAPEX in land, machinery and buildings is estimated to about €200 million. The major part of the investment will be financed with own capital.</p>
				<p>Ogulin&nbsp;2 will be a highly integrated factory with in house production of wood powder, veneer and core material. A new wood-based core has been invented that is moisture proof and environmentally friendly. A major part of the wood raw material, which is needed for such core production, will be wood waste from the Bjelin factories in Ogulin, Bjelovar and Otok.</p>
				<img src={ogulinMap} style={{"maxHeight":"500px"}} alt={"Map of Ogulin"}/>
				<p>Powder mixing, pressing, profiling, lacquering, and packaging will be highly automated and based on production principles developed by Välinge.</p>
				<p>Major investments are also planned to extend the sales and marketing resources and to promote the Bjelin and Välinge brands that will be used to sell floors in Europe, US and Asia.</p>
				<blockquote><p>I am very pleased that after 10 years of hard end very costly development work we are finally ready to scale up our powder based production considerably in order to introduce a new wood flooring generation that is more cost efficient and environmentally friendly in the same way as the three layer parquet was when it was invented and introduced in 1945 by the Swedish companies Kährs and Tarkett. The positive market response gives us the confidence to make a major investment in production in Croatia where we have high quality oak material and skilled workers.</p></blockquote>
				<figcaption>Darko Pervan, CEO Pervanovo Invest AB</figcaption>
				<blockquote><p>We are proud that our owner Pervanovo Invest AB has decided to make this mega investment in Croatia and we are very excited to introduce the Välinge Woodura technology together with our Swedish colleagues. We have during the past two years upgraded all our three factories to a high technology level but this new high-tech investment in Ogulin is something extraordinary that will bring Bjelin to a world leading position. The investment will directly increase our work force in Croatia with 600 employees from 800 to 1 400 but it will also create many new jobs at our factories in Bjelovar and Otok where we will increase our production of top layers and prepare wood waste such that it may be used for our core production.</p></blockquote>
				<figcaption>Stjepan Vojnic, CEO Bjelin Croatia</figcaption>
			</NewsBox>
		</div>
	);
}

export default News;