import React from 'react';
import './PageHeader.scss';

function PageHeader(props) {
	return (
		<header className="pageHeader">
			<div className="content">{props.children}</div>
		</header>
	);
}

export default PageHeader;