import React from 'react';

import heroImg from '../../images/hero.jpg';
import Banner from '../../components/Banner';
import FancyBox from '../../components/FancyBox';
import TriviaBox from '../../components/TriviaBox';
import ContentBox from '../../components/ContentBox';

import { faUsers, faMicroscope, faIndustryAlt, faBuilding} from '@fortawesome/pro-solid-svg-icons'

function Home() {
	return (
		<div className="pageHome">
			<Banner src={heroImg}><h1>Head Office</h1>Viken, Sweden</Banner>
			<FancyBox>
				<h1>Who we are</h1>
				<p>Pervanovo Invest AB is an investment and R&D company since 1993, fully owned by the Pervan family. We believe that through knowledge, technology and highly competent employees we push the industry forward.</p>
				<p>The Pervanovo group has an equity value, based on market value of assets, of over €2,000 million and about 2,500 employees. While the core is still R&D within our majority owned subsidiary Välinge Innovation AB, the group has throughout the years diversified into several different areas, including flooring production and sales, processing of wood based materials, real estate and tourism. A main long-term objective is to become a world leading sustainable and integrated flooring producer based on powder technology developed and patented by Välinge and in-house production of main raw materials.</p>
			</FancyBox>
			<ContentBox>
				<TriviaBox icon={faUsers}>2,500<br/>employees</TriviaBox>
				<TriviaBox icon={faMicroscope}>&gt;3,500<br/>patents</TriviaBox>
				<TriviaBox icon={faIndustryAlt}>7<br/>factories</TriviaBox>
				<TriviaBox icon={faBuilding}>&gt;30<br/>properties</TriviaBox>
			</ContentBox>
		</div>
	);
}

export default Home;