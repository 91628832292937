import React from 'react';
import './Navigation.scss';
// eslint-disable-next-line
import { NavLink } from "react-router-dom";

import logo from '../../images/logo.svg';

function Navigation() {
	return (
		<header id="topHeader">
				<NavLink to="/" activeClassName="current"><img src={logo} className="logo" alt="Pervanovo Logo"/></NavLink>
				<div className="navigation">
					<NavItem to="/businessunits">Business units</NavItem>
					<NavItem to="/about">About</NavItem>
					<NavItem to="/news">News</NavItem>
					<NavItem to="/contact">Contact</NavItem>
				</div>
		</header>
	);
}

function NavItem(props) {
	return (
		<div className="navItem">
			<NavLink to={props.to} activeClassName="current">{props.children}</NavLink>
		</div>
	);
}

export default Navigation;