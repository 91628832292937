import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navigation from '../Navigation';
import Home from '../../pages/Home';
import BusinessUnits from '../../pages/BusinessUnits';
import About from '../../pages/About';
import Contact from '../../pages/Contact';
import Privacy from '../../pages/Privacy';
import NotFound from '../../pages/FileNotFound';
import News from '../../pages/News';
import './App.scss';

function App() {
	return (
		<div className="App">
			<Router>
				<Navigation />
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/businessunits" component={BusinessUnits} />
					<Route exact path="/bjelin" component={BusinessUnits} />
					<Route exact path="/valinge" component={BusinessUnits} />
					<Route exact path="/news" component={News} />
					<Route exact path="/about" component={About} />
					<Route exact path="/contact" component={Contact} />
					<Route exact path="/privacy" component={Privacy} />
					<Route component={NotFound}/>
				</Switch>
			</Router>

		</div>
	);
}

export default App;
