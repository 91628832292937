import React from 'react';
import './Banner.scss';

function Banner(props) {
	let caption = props.children ? <div className="caption">{props.children}</div> : '';
	let className = "banner";
	className += props.sizeToFit ? " sizeToFit" : '';
	return (
		<div className={className} style={{backgroundImage:`url(${props.src})`}}>{caption}</div>
	);
}

export default Banner;