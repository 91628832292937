import React from 'react';
import './FancyBox.scss';

function FancyBox(props) {
	let background = props.background ? {background: props.background} : {};
	let color = props.color ? {color: props.color} : {};
	let className = "fancyBox";
	if (props.className) {
		className += ' '+props.className;
	}
	if (props.color === "white") {
		className += " white";
	}
	let style = Object.assign(background, color);
	return (
		<div className={className} style={style}>
			<div className="content">{props.children}</div>
		</div>
	);
}

export default FancyBox;