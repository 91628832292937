import React from 'react';

import $script from 'scriptjs'
import './Contact.scss';

import FancyBox from '../../components/FancyBox';
import ContentBox from '../../components/ContentBox';
import PageHeader from '../../components/PageHeader';

//JSLint directives
/*global
google
*/
class ContactPage extends React.Component {
	componentDidMount() {
		$script('https://maps.googleapis.com/maps/api/js?key=AIzaSyBsXcS2G5dji5i0RWr5L4sAfQxp9psB7KI', 'GoogleMaps');
		$script.ready('GoogleMaps', () => {
			//const uluru = {lat: 56.15915, lng: 12.57965}
			const startPos = {lat: 52.5130782, lng: 13.4583717};

			let positions = {
				pervanovo: {lat: 56.15915, lng: 12.57965},
				valinge: {lat: 56.1603316, lng: 12.5841764},
				bjelinSwe: {lat: 59.3397429, lng: 18.0809643},
				pervanovoDOO: {lat: 42.6603295, lng: 18.0731158},
				ogulin: {lat: 45.2674581, lng: 15.2268445},
				bjelovar: {lat: 45.893128, lng: 16.8508146},
				otok: {lat: 45.1502914, lng:18.9196261}
			}
			const map = new google.maps.Map(document.getElementById('map'), {
				zoom: 5,
				center: startPos,
				styles: [
	            {elementType: 'geometry', stylers: [{color: '#403434'}]},
	            {elementType: 'labels.text.stroke', stylers: [{color: '#403434'}]},
	            {elementType: 'labels.text.fill', stylers: [{color: '#FDFFFC'}]},
	            {
	              featureType: 'administrative.country',
	              elementType: 'geometry.stroke',
	              stylers: [{color: '#dfe2e1'}]
	            },
	            {
	              featureType: 'road.arterial',
	              elementType: 'geometry.fill',
	              stylers: [{color: '#dfe2e1'}]
	            },
	            {
	              featureType: 'road.local',
	              elementType: 'geometry.fill',
	              stylers: [{color: '#dfe2e1'}]
	            },
	            {
	              featureType: 'landscape.man_made',
	              elementType: 'geometry.fill',
	              stylers: [{color: '#CF0000'}]
	            },
	            {
	              featureType: 'water',
	              elementType: 'geometry',
	              stylers: [{color: '#dfe2e1'}]
	            },
	            {
	              featureType: 'water',
	              elementType: 'labels.text.fill',
	              stylers: [{color: '#100B00'}]
	            },
	            {
	              featureType: 'water',
	              elementType: 'labels.text.stroke',
	              stylers: [{color: '#17263c'}]
	            }
          ]
        	});
			
			addPlace(positions.valinge, "Välinge Innovation", "Prästavägen 513<br> 263 65 Viken, Sweden");
			addPlace(positions.pervanovo, "Pervanovo Invest &amp; Bjelin Sweden", "Apelvägen 2<br> 263 625 Viken, Sweden", true, true);
			addPlace(positions.bjelinSwe, "Bjelin Sweden", "Sibyllegatan 38<br> 114 43 Stockholm, Sweden");
			addPlace(positions.pervanovoDOO, "Pervanovo D.O.O", "Vatroslava Lisinskog 45<br>20 000 Dubrovnik, Croatia");
			addPlace(positions.ogulin, "Bjelin Ogulin production", "Žegar VI/39<br>47300 Ogulin, Croatia");
			addPlace(positions.bjelovar, "Bjelin Bjelovar production", "Slavonska cesta 17<br>43000 Bjelovar, Croatia");
			addPlace(positions.otok, "Bjelin Otok production", "Skorotinci 6<br>32253 Otok, Croatia");

			function addPlace(position, title, description, open, instant) {
				let placer = () => {
					let marker = new google.maps.Marker({
						position: position,
						title: title,
						animation: google.maps.Animation.DROP,
						map: map
					});
					let infoWindow = new google.maps.InfoWindow({
						content: "<b>"+title+"</b><br>"+description
					});
					marker.addListener('click', () => {
						infoWindow.open(map, marker);
					});

					if (open === true) {
						infoWindow.open(map, marker);
					}
				}

				if (instant === true) {
					placer();
				} else {
					setTimeout(placer, 500*Math.random());
					
				}
			}
		})
	}
	render() {
		return (
			<div className="pageContact">
				<PageHeader>Contact</PageHeader>
				<FancyBox>
					<ContentBox>
						<ContactBox title="Pervanovo Invest AB" email="info@pervanovo.se" address={["Apelvägen 2", "263 62, Viken", "Sweden"]}>General</ContactBox>
						<ContactBox title="Swedish Properties" email="properties@pervanovo.se" />
						<ContactBox title="Bjelin Sweden" email="sales@bjelin.com" url="https://www.bjelin.com" address={[<h2>Viken Office</h2>, "Apelvägen 2", "263 62, Viken", "Sweden", <h2>Stockholm Office</h2>, "Sibyllegatan 38", "114 43, Stockholm", "Sweden"]}>Sales & Marketing</ContactBox>
						<ContactBox title="Bjelin Croatia Financials" email="igor.dujic@bjelin.hr" address={["Bjelin/Spin Valis d.d.", "Industrijska 24", "34000 Požega", "Croatia"]}>CFO<br/>Igor Dujić</ContactBox>
						<ContactBox title="Bjelin Croatia" email="anamarija.tesic@bjelin.hr" address={["Žegar VI/39", "47300 Ogulin", "Croatia"]}>Sales & Marketing<br/>Ana-Marija Tešić</ContactBox>
						<ContactBox title="Bjelin Ogulin" email="kruno.piskur@bjelin.hr" address={["Žegar VI/39", "47300 Ogulin", "Croatia"]}>Plant manager<br/>Kruno Piškur</ContactBox>
						<ContactBox title="Bjelin Bjelovar" email="ivan.simek@bjelin.hr" address={["Slavonska cesta 17", "43 000, Bjelovar", "Croatia"]}>Plant manager<br/>Ivan Simek</ContactBox>
						<ContactBox title="Bjelin Otok" email="josip.kaurin@bjelin.hr" address={["Skorotinci 6", "32 253, Otok", "Croatia"]}>Plant manager<br/>Josip Kaurin</ContactBox>
						<ContactBox title="Bjelin Spacva" email="vladimir.batakovic@bjelin.hr" address={["Duga Ulica 181", "321 00, Vinkovci", "Croatia"]}>Plant manager<br/>Vladimir Batakovic</ContactBox>
						<ContactBox title="Pervanovo D.O.O" email="info@pervanovo.hr" address={["Vatroslava Lisinskog 45 p.p. 213", "20 000, Dubrovnik", "Croatia"]} url="http://www.pervanovo.hr">Tourism</ContactBox>
						<ContactBox title="Välinge Innovation AB" email="contact@valinge.se" url="https://www.valinge.se" address={["Prästavägen 513", "263 65, Viken", "Sweden"]}/>
						<ContactBox title="Välinge Croatia" email="nikola.paic@valinge.hr" address={["Otok Oštarski 4K", "Ogulin HR-47300", "Croatia"]} url="https://www.valinge.se">Managing Director<br/>Nikola Paić</ContactBox>
					</ContentBox>
				</FancyBox>
				<ContentBox>
					<div id="map"></div>
				</ContentBox>
			</div>
		);
	}
}

function ContactBox(props) {
	let email, address, title, url;

	if (props.email) {
		email = (<div className="contactItem"><h2>Email</h2><a href={`mailto:${props.email}`}>{props.email}</a></div>);
	}

	if (props.url) {
		let caption;
		if (props.url.startsWith("https://")) {
			caption = props.url.substring(8);
		} else if (props.url.startsWith("http://")) {
			caption = props.url.substring(7);
		} else {
			caption = props.url;
		}
		url = (<div className="contactItem"><h2>Website</h2><a href={props.url}>{caption}</a></div>);
	}

	if (props.title) {
		title = (<div className="contactItem"><h1>{props.title}</h1></div>);
	}

	if (props.address) {
		let addresses = [];
		props.address.forEach(function(el) {
			addresses.push(<li key={Math.random()}>{el}</li>);
		});
		address = (<div className="contactItem"><h2>Address</h2><ul className="contactList">{addresses}</ul></div>);
	}

	return (
		<div className="contactBox">
			{title}
			{props.children}
			{url}
			{email}
			{address}
		</div>
	);
}

export default ContactPage;
