import React from 'react';

import FancyBox from '../../components/FancyBox';
import PageHeader from '../../components/PageHeader';
import Banner from '../../components/Banner';

import './BusinessUnits.scss'

import BjelinLogo from '../../images/bjelin_logo.svg';
import ValingeLogo from '../../images/valinge_logo.svg';
import PHotelsLogo from '../../images/pervanovo_hotels_logo.svg';

import BjelovarAirPhoto from '../../images/bjelovar_air.jpg';
import OtokAirPhoto from '../../images/otok_air_2.jpg';
import Ogulin1AirPhoto from '../../images/ogulin_1_air.jpg';
import Ogulin2AirPhoto from '../../images/ogulin2-construction.jpg';
import ValingeAirPhoto from '../../images/valinge_air.jpg';
import KazbekPhoto from '../../images/kazbek.jpg';
import StortorgetPhoto from '../../images/stortorget.jpg';
import SpacvaAirPhoto from '../../images/spacva_air.jpg';
import BjelinGlavenPhoto from '../../images/bjelin_glaven.jpg';
import BjelinWarehousePhoto from '../../images/bjelin_warehouse.jpg';
import LogsPhoto from '../../images/logs.jpg';


function BusinessUnits() {
	return (
		<div className="pageBusinessUnits">
			<PageHeader>Business Units</PageHeader>
			<FancyBox background="#dfe2e1" color="black">
				<p>Pervanovo has through the years diversified into several different areas, including in-house flooring production and sales, processing of wood based materials, real estate and tourism. The present main business units are categorized as follows:</p>
				<ul>
					<li>R&D, IP and licensing of flooring and furniture technologies.</li>
					<li>Flooring production and sales under the Bjelin brand</li>
					<li>Production of veneer for Woodura and furniture industry</li>
					<li>Production and sales of mainly oak based furniture with click systems</li>
					<li>Production of oak components such as lamellas, elements and glued boards.</li>
					<li>Production of bioenergy such as pellets and briquets as well as green electricity from solar power and ORC technology</li>
					<li>Properties and tourism</li>
				</ul>
			</FancyBox>

			<FancyBox background="#403434" color="white">
				<h1><img src={ValingeLogo} className="unitLogo" alt="Välinge Logo"/></h1>
				<p>Välinge Innovation is an R&D and IP company in the flooring and furniture industries with about 250 employees. Founded in 1993, we pioneered the concept of glueless click flooring and changed the way people install and use floorings. Our worldwide-patented technology introduced a fast and easy way of installing floorboards mechanically, without using glue.</p>
				<p>Today our technology base covers all fields related to floating flooring but also to other applications such as furniture and surfaces. Several new companies have been founded to specialize in customer support as well as different types of R&D and IP activities.</p>
				<Banner src={ValingeAirPhoto}>Välinge R&D centre and Woodura factory</Banner>
				<p>Our patent portfolio is one of the most comprehensive in the flooring industry. It comprises more than 3,500 granted patents and a global license base of over 250 licensees. All R&D activities take place at our facility in Viken which is considered to be the world's most advanced R&D centre related to laminate, wood and wood-powder based flooring technology.</p>
				<p>Since 2017, Välinge has also been expanding into large scale production of powder-based flooring based on its own Woodura and Nadura technology. The new highly automated production facility in Viken has a full capacity of 5,5 million m² per year.</p>
				<p>In a joint project together with Bjelin, Välinge is expanding their production in Croatia with the Ogulin 2 factory, that aims to become the world's largest wood flooring factory.</p>
				<h3><a href="https://www.valinge.se">www.valinge.se</a></h3>
			</FancyBox>

			<FancyBox background="#dfe2e1" color="black">
				<h1><img src={BjelinLogo} className="unitLogo" alt="Bjelin Logo"/></h1>
				<h2>Production and sales</h2>
				<h2>Mission</h2>
				<p>Bjelin is the newest business unit in the Pervanovo group. The main mission of Bjelin is to commercialise new flooring technology, primarily developed by Välinge. This gives us the possibility to test and further develop our flooring technology on a large scale. Furthermore, we bring new products to the market and this creates a pull strategy and support the license business of Välinge.</p>
				<h2>Production</h2>
				<p>Bjelin maintains an exceptionally high level of vertical integration throughout its production and sales processes. This integration spans various stages, including R&D, wood processing, production, logistics, marketing, and software development. The company sources logs directly from the nearby Spačva forest and stores them in its own log yards for processing. These logs are initially upgraded into semi-finished components such as veneer, lamellas, and glued boards. Most of these components are subsequently sent to Bjelin's adjacent factories, where they undergo further refinement to become finished consumer goods, including flooring, furniture, and doors.</p>
				<p>Remaining components not used internally are sold to other furniture and flooring producers and distributors, mainly in the EU.</p>
				<p>Essentially all wood waste is used as bioenergy. Bark and low-quality wood waste is used to heat the kilns, buildings and industrial presses. High quality wood waste such as saw dust and smaller wood pieces are processed into pellets and briquettes, and sold to distributors.</p>
				<Banner src={BjelinWarehousePhoto}>Bjelin warehouse</Banner>
				<h2>Products and production sites</h2>
					<ul>
						<li>The Bjelin flooring products based on Woodura and Nadura technology are produced by Välinge in Viken, Sweden and by Välinge Croatia at the Ogulin 2 site.</li>
						<li>High quality 1-strip, 3-strip and Herringbone parquet flooring with advanced click systems are produced by Bjelin d.o.o. in Ogulin 1, Croatia. Solid oak floors in planks and in herringbone are produced by Bjelin Spacva</li>
						<li>Sliced veneer is produced by Bjelin Spacva and Belin Otok. Individual veneer sheets are connected to large sheets by splicing at Bjelin Otok</li>
						<li>Logs are sawn and dried to elements of oak, beech and ash at Bjelin Spin Valis, Bjelin Bjelovar and Bjelin Spacva.</li>
						<li>Elements are upgraded to 1-strip lamellas at Bjelin Bjelovar and Bjelin Spacva, and to glued boards at Bjelin Spin Valis and Bjelin Spacva.</li>
						<li>Furniture, mainly based on glued boards of solid oak, is produced by Bjelin Spin Valis and Bjelin Spacva</li>
						<li>Interior doors based on solid oak are produced at Bjelin Spacva</li>
						<li>Pellets and briquettes are produced by Bjelin in Ogulin 1 and by Bjelin Spacva. Briquettes are also produced by Bjelin Spin Valis.</li>
						<li>Electricity based on solar panels is produced by Bjelin Spin Valis, Bjelin Spacva and Bjelin Otok</li>
						<li>Electricity based on wood waste and ORC co-generation is produced at Bjelin Spin Valis.</li>
					</ul>
				<Banner src={BjelinGlavenPhoto}>Glaven, Bjelin Sweden HQ</Banner>
				<h2>Marketing and sales</h2>
				<p>All floors are sold internationally under the Bjelin brand via distributors, to projects and directly to end-consumers online. We are continuously extending our network of local warehouses and showrooms all over the world. We presently operate showrooms in Viken, Helsingborg, Gothenburg, Stockholm, Köping, Alpharetta (Georgia), Zagreb, Shanghai and Kunshan, which we aim to expand over the coming years.</p>
				<p>Semi-finished components are sold under the Bjelin brand directly to other producers or distributors. Furniture sales to distributors that resell our furniture under their own brands will be extended with direct sales to projects and with online direct sales of flat pack click furniture under our own brands.</p>
				<p>Our objective is to provide the most cost efficient, environmentally friendly and high-quality products in the industry. To reach this objective we use unique technology and highly automated equipment to produce the final products but also the main raw materials. Our highly integrated production makes it possible to control all production steps from the forest to the packed final product.</p>
				<p>Our highly integrated production makes it possible to control and optimise all production steps from the forest to the packed final product. Low transportation distances from the forest to our factories reduce our costs and our CO₂ footprint.</p>
				<h3><a href="https://www.bjelin.com">www.bjelin.com</a></h3>
			</FancyBox>
			<FancyBox background="white" color="black">
				<h1>Croatian Wood Industry</h1>
				<p>With our 6 plants and 2,100 employees, we are the largest wood processing industry in Croatia and the largest oak veneer producer in Europe. Our core business is to upgrade the Croatian oak to high value products mainly for flooring applications. Our 2 veneer mills in Otok and Vinkovci have a capacity of 25 million m² of oak veneer. Throughout the coming years, considerable investments will be made to increase the veneer capacity to 35 million m² of high-tech veneer mainly for flooring applications.</p>
				<h2>Ogulin 1</h2>
				<p>The flooring plant at our Ogulin 1 site has 350 employees and a capacity of 1.6 million m² of flooring. The main products are three-layer parquet and parquet strips for herringbone installation.</p>
				<p>The Ogulin 1 plant has its own sawmill where soft wood is sawn, dried in modern kilns and processed to lamellas for the middle layer part of the Parquet flooring. The top lamellas are produced at our factory in Bjelovar. Saw dust and wood waste is used as raw material for our pellet and briquette production.</p>
				<p>Our new line for Herringbone parquet floors with click systems has a capacity of 0.6 million m². Production is being extended with Woodura based herringbone.</p>
				<Banner src={Ogulin1AirPhoto}>Ogulin 1 flooring factory</Banner>
				<h2>Ogulin 2</h2>
				<p>The Ogulin 2 project, is our largest and most strategic investment. The aim is to build the world's largest and completely integrated wood flooring company. All major raw materials such as the veneer for the surface and backing layers, the core and the wood powder will be sustainable wood materials that will be produced in house. This new mega factory will manufacture floors according to Välinge's Woodura® and Nadura® technologies. The production capacity will be 4 times larger than the capacity of the largest existing wood flooring factory in the world.</p>
				<p>The land and building are owned by Bjelin, while the machinery is owned and operated by Välinge.</p>
				<p>Ogulin 2 will be expanded in several steps over the coming years. Step one comprising of two pressing lines, two processing lines, energy plant and powder mixing plant including several warehouses will increase our Woodura® production capacity with 10 million m², totaling up to 15 million m² per year together with the Viken factory. </p>
				<Banner src={Ogulin2AirPhoto}>Ogulin 2 construction site</Banner>
				<p>Our Viken factory and warehouses in Helsingborg will be coordinated with Ogulin 2 in order to decrease production costs and allow a more streamlined production range at both locations. The Viken facilities are very advanced and designed for both R&D and production of a wide range of advanced powder-based floors. The Ogulin 2 factory is designed for only low cost and high-volume production of Woodura® floors.</p>
				<p>Step 2 aims to expand production at Ogulin 2 with four processing lines and one unique board factory, based on our own HDPB technology, that is designed to produce 20 million m² of board materials with an integrated veneer top layer. This integrated production method developed and patented by Välinge makes it possible to avoid four separate pressing lines and to reduce energy consumption and production costs considerably. The two pressing lines of the step 1 investment that initially will be used for pressing of Woodura® boards are prepared to be converted to Nadura® press lines when the integrated Woodura® production is introduced.</p>
				<p>The Ogulin 2 production facility emphasizes sustainability. Instead of burning wood waste for bioenergy, we'll utilize it to create a wood-based core capable of storing CO₂ for over 30 years. All our flooring is designed for recyclability, making it possible to repurpose them as new core materials. This includes even older parquet floors. A significant competitive edge is the proximity of our production sites in Croatia. Such closeness facilitates cost-effective transportation of wood waste from our various factories directly to Ogulin 2.</p>
				<p>Utilising solar panels and thermal energy to achieve low emissions, coupled with the short transportation distances from the forest to our production facilities, will bolster our sustainability profile. The new wood-based core will be moisture-resistant, paving the way to replace ceramic tiles with our sustainable wood-based Nadura® products.</p>
				<p>Step 2 involves an investment of approximately 3 billion SEK and will augment our production capacity at Ogulin 2 to 30 million m². Of this, 20 million m² will be Woodura® floors and 10 million m² will be Nadura® floors.</p>
				<h2>Bjelovar</h2>
				<p>Our Bjelovar plant produces the top layer lamellas for our parquet flooring. We saw FSC-certified oak logs in our sawmill, dry them in state-of-the-art kilns, and process them into high-quality lamellas. These lamellas are then shipped to Ogulin1 or supplied to other flooring manufacturers. We've recently completed significant investments in a modern log yard and a new sawmill at this location. The Bjelovar site employs around 300 individuals.</p>
				<Banner src={BjelovarAirPhoto}>Bjelovar lumber mill</Banner>
				<h2>Otok</h2>
				<p>The primary raw material for our Woodura® floors is the oak wood veneer, produced by Bjelin Otok. Situated in the heart of the renowned Spacva forest in Croatia, Spacva stands out as one of Europe's largest undisturbed oak forests, celebrated for its premium FSC-certified oak quality.</p>
				<p>Bjelin Otok ranks amongst Europe's most contemporary veneer plants, renowned for its exceptionally high-quality oak veneer. The Otok facility boasts a splicing factory equipped with two modern splicing lines, tailored specifically for flooring applications. Here, individual veneers are arranged randomly, examined, and adhered together into expansive sheets. This process ensures streamlined veneer application in our Woodura® pressing lines. We are currently channeling investments into a second splicing factory.</p>
				<p>Our continued investments are set to augment the veneer capacity to 15 million m² and the veneer splicing capacity to 20 million m². We've incorporated solar systems for electricity generation and have expanded the log yard.</p>
				<p>The Otok site employs approximately 200 individuals.</p>
				<Banner src={OtokAirPhoto}>Otok veneer factory</Banner>
				<h2>Spacva</h2>
				<p>Bjelin Spacva is situated in Vinkovci, in close proximity to our Otok veneer mill and adjacent to the renowned Spacva forest in Croatia. This forest is celebrated for its outstanding FSC-certified oak quality. Bjelin Spacva's primary product is oak veneer, catered for both flooring and furniture applications. Additionally, Bjelin Spacva features a state-of-the-art, highly automated element line. Here, logs unsuitable for veneer production are transformed into elements and then further refined into glued boards, solid wood floors, lamellas for parquet flooring, doors, and furniture. Biomass and wood waste are utilised to produce pellets. For power generation, solar systems have been set up on site.</p>
				<p>We're currently directing significant investments towards establishing Europe's largest and most cost-efficient oak veneer factory at this location, aiming for a capacity surpassing 20 million m².</p>
				<p>The Spacva facility employs around 800 individuals.</p>
				<Banner src={SpacvaAirPhoto}>Spacva oak processing factory</Banner>
				<h2>Spin Valis</h2>
				<p>Spin Valis specialises as an integrated producer, focusing predominantly on high-quality solid oak furniture. Logs undergo a transformation, being sawn and processed into elements. These elements subsequently contribute to the production of finger-joined and edge-glued boards made from oak and birch. These boards find their purpose in internal furniture production and are also marketed as semi-finished products to European distributors. Both the element line and the glued board line are modern and cost-efficient.</p>
				<p>Noteworthy investments have been channelled into the establishment of a state-of-the-art R&D centre dedicated to furniture development and prototype manufacturing.</p>
				<p>Spin Valis boasts two solar plants with a combined capacity of 1 MW, alongside two cogeneration ORC plants, fuelled by wood waste, possessing a total capacity of 2 MW. The generation of electrical energy notably surpasses internal consumption.</p>
				<p>Expansion plans for the furniture business include the introduction of veneered furniture. Additionally, Välinge's innovative click technology, optimised for furniture assembly, is set to be incorporated.</p>
				<p>Spin Valis employs around 600 individuals.</p>
			</FancyBox>

			<FancyBox>
				<h1>Our oak from Spacva forest</h1>
				<p>Optimal product quality and material yield are contingent upon two main factors: the proximity of the forest to the log yard and effective protection of logs, especially during the summer months. This protective measure is crucial to maintain the integrity and quality of the logs.</p>
				<Banner src={LogsPhoto}>Oak logs</Banner>
				<p>Our veneer facilities at Bjelin Spacva and Bjelin Otok handle the majority of oak veneer logs sourced from the Spačva Forest, renowned as Europe's largest uninterrupted oak woodland. The advantage of extremely brief transport routes cannot be overstated, offering tangible benefits in cost savings and superior product quality.</p>
				<p>We pride ourselves on our three cutting-edge log yards located in Bjelovar, Vinkovci, and Otok. These facilities are kitted out with advanced systems for spraying, cleaning, and water recirculation. Collectively, they boast the capacity to securely store and preserve up to 70,000 m³ of oak logs.</p>
			</FancyBox>

			<FancyBox background="#dfe2e1" color="black">
				<h1><img src={PHotelsLogo} className="unitLogo" alt="Pervanovo Hotels Logo"/></h1>
				<p>Pervanovo owns and operates a number of high rated hotels and apartments in and around the Dubrovnik area.</p>
				<p>Our objective is to acquire older properties in the best locations in and around Dubrovnik and create luxury Dubrovnik hotels, restored to their former glory. Our policy is to maintain as much of the original building as possible whilst at the same time refurbishing to the highest possible standard and comfort levels. Once completed these objects are used in the tourist industry and offer some of the best available accommodation in Dubrovnik.</p>
				<Banner src={KazbekPhoto}>Kazbek hotel</Banner>
			</FancyBox>

			<FancyBox background="#403434" color="white">
				<h1>Real estate</h1>
				<p>Since 2002 Pervanovo has acquired about 30 properties in Sweden and Croatia that are rented out, used in tourist business or as capital investments.</p>
				<p>The market value of our properties is about €250 million, considerably higher than the book value which is based on the original investment and reduced with annual depreciations. 60% of our investments are in Sweden and 40% in Croatia.
				All properties are located at attractive locations. Over the years the value has increased with about 5% per year.
				Our properties form a capital reserve that may be used to provide extensive financial resources if needed.</p>
				<Banner src={StortorgetPhoto}>Stortorget 7, Helsingborg</Banner>
			</FancyBox>
		</div>
	);
}

export default BusinessUnits;
