import React from 'react';
import './About.scss'

import FancyBox from '../../components/FancyBox';
import PageHeader from '../../components/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from '@fortawesome/pro-solid-svg-icons'

import OwnershipChart from '../../images/ownership_chart.svg';

function About() {
	return (
		<div className="pageAbout">
			<PageHeader>About us</PageHeader>
			<FancyBox>
				<h1>Who we are</h1>
				<p>Pervanovo Invest AB is an investment and R&D company since 1993, fully owned by the Pervan family. We believe that through knowledge, technology and highly competent employees we push the industry forward.</p>
				<p>The Pervanovo group has an equity value, based on market value of assets, of over €2,000 million and about 2,500 employees. While the core is still R&D within our majority owned subsidiary Välinge Innovation AB, the group has throughout the years diversified into several different areas, including flooring production and sales, processing of wood based materials, real estate and tourism. A main long-term objective is to become a world leading sustainable and integrated flooring producer based on powder technology developed and patented by Välinge and in-house production of main raw materials.</p>
			</FancyBox>

			<FancyBox background={"#dfe2e1"} color="black">
				<h1>Mission and values</h1>
				<p>Our mission is to change the way flooring, furniture and wall panels are produced and used by introducing new technologies that provide better, more cost efficient and more environmentally friendly solutions. </p>
				<p>The core of our business is based on wood material which is an extremely environmentally friendly raw material. 1 kg of dry wood captures and stores 1.8 kg of CO2. All wood-based product that we develop must have a positive impact on the environment.</p>
				<p>Our business is based on long term investments that initially during several years do not generate a positive cashflow. However, our investments are always connected to our present business, made in market segments that are expected to grow above average and in technology fields where we have the possibility to reach and maintain a world leading position.</p>
				<p>Our new technologies are protected by 3,500 patents and this put us in a strong position. We do not misuse that position. We treat suppliers fairly and behave respectfully towards both competitors and customers. Our aim is to grant licenses on fair and reasonable terms. We never use our patents to block competition. We respect patents owned by other parties and when necessary the aim is to enter into cross license agreements on fair and reasonable terms in order to provide legal security for our licensees.</p>
				<p>Development and introduction of new technologies is always risky. We are prepared to take such risks and to invest a major part of our profits in new R&D projects. We are not restricted by the general opinion that “this has not been done before”.</p>
				<p>We believe that integrated in-house production of main raw materials is a key to high production efficiency and high product quality</p>
				<p>The group has during many years been involved in on-line marketing and sales of flooring products based on own products and our Bjelin brand. Our intention is to extend this business segment considerably together with own local warehouses that may provide a high service level to our customers.</p>
				<p>We believe that superior knowledge and skills amongst our employees is a must to maintain our leading positions and we support education and training in order improve the skills of our employees. </p>
				<p>We support and respects the protection of internationally proclaimed human rights. We are of the deepest conviction that all humans are equal, regardless of race, sex, sexual orientation, nationality, religion, political opinion, ethnic background and social origin.</p>
			</FancyBox>
			<FancyBox>
				<h1>Key figures 2022</h1>
				<p>The equity value of the whole Pervanovo group is about €2,000 million. The net equity value after reduction of the Kirkbi's minority holding is about €1,300 million. Our main ongoing investment projects, the Ogulin 2 Woodura factory and new veneer factories, that are anticipated to increase our EBITDA with more than €100 million are not included in these valuations.</p>
				<p>Our sales in 2022 increased with about 17% to €287 million and our EBITDA increased with 22% to €101 million. </p>
				<p><a href={"https://storage.pervanovo.se/downloads/Pervanovo%20Consolidated%20annual%20report%20January%20%E2%80%93%20December%202022.pdf"} download><FontAwesomeIcon icon={faDownload} /> Download annual report</a></p>
			</FancyBox>
			<FancyBox background={"#dfe2e1"} color="black">
				<h1>Ownership and management</h1>
				<p>Pervanovo Invest has since its foundation in 1993 been fully owned by the Pervan family which holds main management positions.</p>
				<p>Darko Pervan - Chairman and CEO</p>
				<p>Tony Pervan - Marketing and logistics</p>
				<p>Daniel Pervan - IT and development</p>
				<p>Pervanovo is the majority shareholder in the Välinge Group with a shareholding of 50,1% of the shares. The Danish investment company Kirkbi AS, that controls the Lego group, owns 48,4% of the shares, whereas the management team of Välinge owns 1,5% of the Välinge shares</p>
				<p>All other companies and properties of the group are owned 100% by Pervanovo Invest AB.</p>
				<p>The family ownership based on majority shareholdings provides stability and active long-term ownership.</p>
				<img src={OwnershipChart} className="ownershipChart" alt="Ownership Chart"/>
			</FancyBox>
			<FancyBox>
				<h1>History in brief</h1>
				<h2>1977</h2>
				<p>Swedish company Perstorp AB develops the first laminate flooring.The development was made on the initiative of Darko Pervan, at that time the general manager of the Perstorp laminate division. This development was the foundation of Pergo and the whole laminate flooring industry.</p>
				<h2>1993</h2>
				<p>The first company of the Pervanovo grup, Välinge, is founded in 1993 by Darko Pervan. The same year, Darko Pervan leads the development of the world’s first mechanical locking system that enables installation of wood and laminate flooring by combining angling and snapping. </p>

				<h2>1994</h2>
				<p>Välinge signs its first license agreements with Norske Skog (Alloc) for laminate floorings and with Kährs for wood floorings.The basic Välinge principles for locking floorboards mechanically gradually develops to a world standard. About 10 years later close to one billion m² of laminate and wood floors are annually installed with mechanical click systems. The new glue free technology starts an evolution that eliminates 100 million glue bottles that annually would have to be used to glue floating laminate and wood floorings.</p>

				<h2>2002</h2>
				<p>Nordstjernan acquires 50% of the Välinge shares from Pervanovo. Välinge moves to its new 5,000 m² R&D center in Viken which is the largest R&D center in the world for development of mechanical locking systems.</p>
				<h2>2003</h2>
				<p>Pervanovo make its first diversification and acquires several properties in Sweden</p>
				<h2>2004</h2>
				<p>A Croatian investment company Pervanovo d.o.o. is founded and several properties are acquired in Dubrovnik</p>
				<h2>2006</h2>
				<p>Kronospan and Swiss Krono Group acquires 40% of Välinge shares. Nordstjernan and Pervanovo maintains 60% of the shares.</p>

				<h2>2007</h2> 
				<p>A new paper-free Wood Fibre Floor (WFF) technology is born 30 years after the first laminate floor invention. Thereby a new milestone is reached as Välinge develops a completely new powder based flooring categories Nadura and Woodura. </p>

				<h2>2008</h2>
				<p>Pervanovo d.o.o opens its first hotel in Dubrovnik</p>

				<h2>2009</h2>
				<p>Välinge moves to its new 17,000 m² R&D center: the largest facility in the world for development of laminate, wood and powder based flooring technology.</p>
				<h2>2011</h2>
				<p>Pervanovo acquires all shares in Välinge from Nordstjernan and increase its ownership to 60%.</p>

				<h2>2013</h2>
				<p>Välinge presents a new digital printing technology (BAP). Water-based transparent blank ink applied with conventional print heads creates a digital binder pattern on a surface. Dry ink particles containing pigments are scattered on the whole surface and on the liquid pattern. Non-bonded particles are subsequently removed.</p>
				Välinge’s locking technology for easier and faster furniture assembly is presented for the first time. 
				<h2>2016</h2>
				<p>KKR acquires 40% of the Välinge shares from Krono and 9.7% from Pervanovo Invest that maintains 50.3% ownership.
				Pervanovo Invest acquires all shares of the Croatian parquet producer Tehnodrvo - now Bjelin d.o.o with production in Ogulin and Bjelovar.
				Pervanovo forms a sales organization in Sweden and Croatia. Parquet, Woodura and Nadura floorings are sold under the new BJELIN brand 
				Pervanovo initiates investments in Viken with a capacity of 5 million m² of Woodura and Nadura flooring.</p>
				<h2>2018</h2>
				<p>The Danish investment company Kirkbi that controls the Lego group acquires all 49.7% of the shares in Välinge from KKR. Pervanovo maintains the majority with 50.3% of the shares.
				The parquet factory in Ogulin is extended and a new production line for Herringbone strips is installed. A second production site – Ogulin 2 – is acquired and land works are initiated for a new Woodura factory.
				Pervanovo d.o.o. acquires the veneer factory Furnir Otok, now Bjelin Otok. The veneer will be used as raw material for the Woodura production in Viken and Ogulin 2.</p>
				<h2>2019</h2>
				<p>Pervanovo makes its first industrial investment in Helsingborg to support flooring sales. The investment comprises a 10,000 m² building with warehouse, showroom and offices.</p>
				<p>Bjelin Croatia starts the building of a new saw mill in Bjelovar to increase efficiency and capacity of oak lamellas for parquet flooring</p>
				<p>Bjelin Croatia forms a sales and marketing company (Bjelin Croatia d.o.o) for parquet and powder-based floors Woodura and Nadura.</p>
				<h2>2020</h2>
				<p>Bjelin Sweden AB forms new sales companies in Denmark and Finland.</p>
				<p>Construction starts to build world's largest wood flooring factory.</p>
				<p>The new factory "Ogulin 2" located close to Bjelins present "Ogulin 1" parquet factory in Ogulin, Croatia, will significantly increase the Woodura capacity in several steps from 2023. When completed in 2027, Ogulin 2 will become the largest wood flooring factory in the world with a production capacity exceeding 20 million m². Total flooring capacity, including Viken and Ogulin 1 will be around 30 million m². The total investment exceeds €200 million.</p>
				<p>Bjelin Otok extends its facilities with 4 000 m² of new buildings and a new splicing line to meet the growing demand for Woodura veneer.</p>
				<h2>2021</h2>
				<p>Pervanovo acquires Spacva d.d. and thereby becomes the largest wood processing company in Croatia and the largest oak veneer producer in Europe.</p>
			</FancyBox>
		</div>
	);
}

export default About;