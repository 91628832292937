import React from 'react';
import './TriviaBox.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TriviaBox(props) {
	return (
		<div className="triviaBox">
			<div className="content">{props.children}</div>
			<div className="icon"><FontAwesomeIcon icon={props.icon} /></div>
		</div>
	);
}

export default TriviaBox;